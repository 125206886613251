import { useField, useFormikContext } from 'formik'
import { Field } from 'formik'
import { useEffect } from 'react'
import { FormikValues } from 'formik/dist/types'
import { get } from 'lodash-es'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { dotProps, labelProps, spanProps } from '../checkbox-group/formik-checkbox-group'
import { type CheckboxPropsCommon } from './index'
import * as UI from '@/ui'

export type FormikCheckboxProps = {
  label?: string
  optionStyle?: 'button' | 'checkbox'
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & CheckboxPropsCommon

export const FormikCheckbox = ({
  name,
  label,
  errorMessage,
  depends,
  setIsHidden,
  optionStyle = 'checkbox',
  ...props
}: FormikCheckboxProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name, ...props })

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    name: 'Form.Checkbox',
    className: `focus:ring-selphAmber-500 h-4 w-4  ${
      errorMessage
        ? 'ring-red-500 text-red-600 border-red-500'
        : meta.touched
          ? 'ring-green-500 text-green-600 border-green-300'
          : 'text-selphBlue-400 border-gray-300'
    }`,
  })

  const value = name.match(/[^[]+\[[^\]]+\]/)
    ? get(values, name.replace(/\[([^\]]+)\]/, '.$1'))
    : (values as FormikValues)[name]

  const selected = ![false, undefined].includes(value)

  return (
    <div>
      {
        {
          checkbox: (
            <UI.Block gap="xs">
              {label ? (
                <UI.Form.Label type="option" text={label}>
                  <Field type="checkbox" name={name} className={className} {...props} />
                </UI.Form.Label>
              ) : (
                <Field type="checkbox" name={name} className={className} {...props} />
              )}

              {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
            </UI.Block>
          ),
          button: (
            <div className="-mr-3 -mb-3">
              <div className="inline-block">
                <label {...labelProps(selected, errorMessage)}>
                  <Field type="checkbox" name={name} className="hidden" {...props} />
                  <div {...dotProps(selected)}></div>
                  {label && <span {...spanProps(selected)}>{label}</span>}
                </label>
              </div>
            </div>
          ),
        }[optionStyle]
      }
    </div>
  )
}

FormikCheckbox.displayName = 'Form.Checkbox.FormikCheckbox'

export default FormikCheckbox
