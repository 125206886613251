import { useUi, type Breakpoints } from './use-ui'

export type HeadingSizes = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xl'
export type HeadingColors = 'white' | 'orange' | 'amber' | 'black' | 'green' | 'none'
export type HeadingWeights = 'thin' | 'semiBold' | 'regular'
export type HeadingProps = {
  size?: Breakpoints<HeadingSizes>
  weight?: Breakpoints<HeadingWeights>
  color?: Breakpoints<HeadingColors>
  className?: string
  as?: keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'>
  children: React.ReactNode
}

export const Heading = ({
  className,
  size,
  color = 'black',
  weight = 'regular',
  as = 'h1',
  children,
}: HeadingProps) => {
  const colors = {
    none: '',
    white: 'text-selphWhite-500',
    black: 'text-selphBlack',
    green: 'text-selphGreen-500',
    amber: 'text-selphAmber-500',
    orange: 'text-selphOrange-500',
  }

  const defaultStyles = {
    h1: { size: 'xl' },
    h2: { size: 'xl' },
    h3: { size: 'xl' },
    h4: { size: 'xl' },
    h5: { size: 'xl' },
    p: { size: 'xxs' },
  }

  const { size: defaultSize } = defaultStyles[as]

  const weights = {
    default: 'font-[helvetica]',
    thin: 'font-thin',
    regular: 'font-normal',
    semiBold: 'font-semibold',
  }

  const sizes = {
    xxs: 'text-xl leading-tight', // Tailwind: sm:text-xl  md:text-xl  lg:text-xl  xl:text-xl
    xs: 'text-2xl leading-tight', // Tailwind: sm:text-2xl  md:text-2xl  lg:text-2xl  xl:text-2xl
    small: 'text-3xl leading-tight', // Tailwind: sm:text-3xl  md:text-3xl  lg:text-3xl  xl:text-3xl
    medium: 'text-4xl leading-tight', // Tailwind: sm:text-4xl  md:text-4xl  lg:text-4xl  xl:text-4xl
    large: 'text-5xl leading-tight', // Tailwind: sm:text-5xl  md:text-5xl  lg:text-5xl  xl:text-5xl
    xl: 'text-6xl leading-snug', // Tailwind: sm:text-6xl  md:text-6xl  lg:text-6xl  xl:text-6xl
  }

  const ui = useUi({
    styles: {
      sizes: { options: sizes, selected: size || defaultSize },
      colors: { options: colors, selected: color },
      weights: { options: weights, selected: weight },
    },
    name: 'Heading',
    className,
  })

  const Tag = as

  return <Tag className={ui.className}>{children}</Tag>
}

Heading.displayName = 'Heading'

export default Heading
