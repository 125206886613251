import { useUi } from '../../use-ui'

import { type TextPropsCommon, boxSizes } from './index'
import { cn } from '@/helpers/tailwind'
import * as UI from '@/ui'

export type InputTextProps = {
  initialValue?: string | number | undefined
} & TextPropsCommon

export const InputText = ({
  name,
  field = 'text',
  boxSize,
  errorMessage,
  placeholder,
  initialValue,
  readOnly,
  ...props
}: InputTextProps) => {
  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Text',
    className: cn(
      errorMessage
        ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphRed-400 bg-transparent text-selphRed-400'
        : 'border-selphWhite-600 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack  text-selphBlack bg-transparent ',
      'focus:outline-hidden sm:text-sm rounded-full w-full border',
      props.className,
    ),
  })

  return (
    <>
      <input
        type={field}
        name={name}
        readOnly={readOnly === 'readOnly'}
        defaultValue={initialValue}
        placeholder={placeholder}
        {...props}
        className={className}
      />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputText.displayName = 'Form.Text.InputText'

export default InputText
