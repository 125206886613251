import { Field, useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { omit } from 'lodash-es'
import { useEffect, useState } from 'react'
import { fromStringDate, DateStamp, importDate, toStringDate } from '../../../../helpers/date'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type DatePropsCommon, boxSizes } from './index'
import AlertCircleIcon from '@/images/icons/alertCircleIcon.svg?svgr'
import CircleCheckIcon from '@/images/icons/circleCheckIcon.svg?svgr'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'

export type FormikDateProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & DatePropsCommon

export const FormikDate = ({
  name,
  minDate,
  maxDate,
  errorMessage,
  boxSize,
  placeholder,
  onKeyDown,
  onChange,
  onBlur,
  depends,
  setIsHidden,
  format = 'date',
  readOnly,
  ...props
}: FormikDateProps) => {
  const { isSubmitting, values } = useFormikContext()

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const [fieldProps, meta, { setValue, setTouched, setError }] = useField({ name })
  const [changed, setChanged] = useState<boolean>()
  const [clearErrpr, setClearError] = useState<boolean>()

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Date',
    className: cn(
      readOnly === 'readOnly'
        ? 'border-gray-400 text-gray-400 cursor-not-allowed ring-transparent placeholder-gray-400 bg-gray-100'
        : errorMessage
          ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphRed-400 bg-transparent text-selphRed-400'
          : meta.touched
            ? 'border-selphGreen-400 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphGreen-400 bg-transparent'
            : 'border-selphWhite-600 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphBlack bg-transparent ',
      'focus:outline-hidden sm:text-sm rounded-full w-full border',
      props.className,
    ),
  })

  useEffect(() => {
    if (
      !changed &&
      meta.initialValue === fieldProps.value &&
      typeof fieldProps.value === 'string' &&
      fieldProps.value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)
    ) {
      if (readOnly) {
        const currentValue = fieldProps.value as Date | string
        setValue(currentValue instanceof Date ? toStringDate(importDate(currentValue)) : fieldProps.value)
      } else {
        setValue(fromStringDate(fieldProps.value).toJSDate())
      }

      setChanged(true)
      setClearError(true)
    } else if (changed && clearErrpr) {
      setError(undefined)
      setClearError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps.value])

  if (!fieldProps) {
    return <p>Loading...</p>
  }

  const parseDate = (date: string | Date | DateStamp | undefined | null): Date | undefined =>
    date
      ? date instanceof Date
        ? date
        : date instanceof DateStamp && typeof date != 'string'
          ? date.toJSDate()
          : typeof date === 'string' && date.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)
            ? fromStringDate(date).toJSDate()
            : undefined
      : undefined

  return (
    <>
      <div className="relative z-1 mt-1 rounded-md shadow-xs">
        {readOnly ? (
          <Field name={name} type="text" placeholder={placeholder} {...props} className={className} />
        ) : (
          <DatePicker
            todayButton="Today"
            {...(format === 'datetime' ? { showTimeSelect: true } : {})}
            {...fieldProps}
            name={name}
            placeholderText={placeholder}
            {...omit(props, ['type', 'ref', 'onSelect'])}
            className={className}
            selected={parseDate(fieldProps.value)}
            onKeyDown={(val) => {
              setValue(val)
              setChanged(true)
              onKeyDown && onKeyDown(val)
            }}
            onChange={(val, extra) => {
              setValue(val)
              setChanged(true)
              onChange && onChange(val, extra)
            }}
            onBlur={(e) => {
              setTouched(true)
              onBlur && onBlur(e)
            }}
            dateFormat={
              {
                date: 'dd/MM/yyyy',
                datetime: 'dd/MM/yyyy HH:mm',
              }[format]
            }
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            minDate={parseDate(minDate)}
            maxDate={parseDate(maxDate)}
            calendarStartDay={1}
          />
        )}

        {errorMessage && (
          <AlertCircleIcon
            className="pointer-events-none absolute top-2.5 right-3 h-5 w-5 text-selphRed-400"
            aria-hidden="true"
          />
        )}

        {(meta.touched || readOnly) && !errorMessage && (
          <CircleCheckIcon
            className="pointer-events-none absolute top-2.5 right-3 h-5 w-5 text-selphGreen-400"
            aria-hidden="true"
          />
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikDate.displayName = 'Form.Date.FormikDate'

export default FormikDate
