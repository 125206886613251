import * as React from 'react'
import { BasicButton, BasicButtonProps, BasicButtonSizes, BasicButtonColors } from './basic-button'
import { SolidButton, SolidButtonColors, SolidButtonProps } from './solid-button'
import { IconButton, IconButtonColors, IconButtonProps } from './icon-button'
import * as UI from '@/ui'

export type ButtonColors = BasicButtonColors | SolidButtonColors | IconButtonColors
export type ButtonSizes = BasicButtonSizes
export type ButtonThemes = ButtonPropsBasic['theme'] | ButtonPropsSolid['theme'] | ButtonPropsIcon['theme']

export type ButtonProps = ButtonPropsBasic | ButtonPropsSolid | ButtonPropsIcon

export interface ButtonPropsShared extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'color' | 'type'> {
  disabled?: boolean
  className?: string
  rootClassName?: string
  uiComponent?: string
  arrow?: boolean
  icon?: React.FC<React.ComponentProps<'svg'>> | React.FC<React.SVGProps<SVGElement>>
  isRunning?: boolean
  errorMessage?: string | null
  children?: React.ReactNode
}

export type ButtonPropsBasic = {
  theme?: 'basic'
} & BasicButtonProps

export type ButtonPropsSolid = {
  theme?: 'solid'
} & SolidButtonProps

export type ButtonPropsIcon = {
  theme?: 'icon'
} & IconButtonProps

export const Button = ({
  errorMessage,
  rootClassName = '',
  children,
  icon,
  size,
  type = 'button',
  theme = 'solid',
  textSize,
  ...props
}: ButtonProps) => {
  const Icon = icon
  theme = type === 'text' ? 'basic' : theme

  if (theme === 'icon')
    return (
      <IconButton icon={icon} size={size as ButtonPropsIcon['size']} {...(props as Omit<IconButtonProps, 'size'>)} />
    )
  return (
    <div
      className={`${rootClassName} ${
        ['xs', 'small', 'medium', 'xl'].includes(size as BasicButtonSizes) ? 'inline-block' : 'block'
      }`}
    >
      <UI.Block
        gap="xs"
        className={['xs', 'small', 'medium', 'xl'].includes(size as BasicButtonSizes) ? 'w-fit' : 'w-full'}
      >
        {
          {
            basic: (
              <BasicButton
                type={type as ButtonPropsBasic['type']}
                size={size as ButtonPropsBasic['size']}
                {...(props as Omit<ButtonPropsBasic, 'size' | 'type' | 'theme'>)}
              >
                {Icon && <Icon className={`inline-block h-5 w-5 ${children ? 'mr-1' : ''}`} />}
                {children}
              </BasicButton>
            ),
            solid: (
              <SolidButton
                type={type as ButtonPropsSolid['type']}
                size={size as ButtonPropsSolid['size']}
                textSize={textSize as SolidButtonProps['textSize']}
                {...(props as Omit<SolidButtonProps, 'size' | 'type' | 'theme'>)}
              >
                {children}
              </SolidButton>
            ),
          }[theme]
        }

        {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
      </UI.Block>
    </div>
  )
}

Button.displayName = 'Button'

export default Button
