import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import * as UI from '@/ui'

export type InputPasswordProps = {
  label: string
  name: string
  placeholder: string
  required?: boolean
  className?: string
}

export const InputPassword = ({ name, label, placeholder }: InputPasswordProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <UI.Block gap={'xs'}>
      <label htmlFor="password" className="font-medium">
        {label}
      </label>
      <div className="relative w-full">
        <input
          name={name}
          type={passwordVisible ? 'text' : 'password'}
          placeholder={placeholder}
          className="relative w-full rounded-md border px-3 py-2 pr-10 text-sm leading-tight focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-hidden"
        />

        <button
          type="button"
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="absolute inset-y-0 right-0 flex items-center pl-3"
        >
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {passwordVisible ? (
              <>
                <span className="w-40 text-sm text-gray-500">Password is shown</span>
                <EyeSlashIcon className="h-6 w-6 text-gray-500 hover:text-blue-500" />
              </>
            ) : (
              <>
                <span className="w-40 text-sm text-gray-500">Password is hidden</span>
                <EyeIcon className="h-6 w-6 text-gray-500 hover:text-blue-500" />
              </>
            )}
          </div>
        </button>
      </div>
    </UI.Block>
  )
}

InputPassword.displayName = 'Form.InputPassword'

export default InputPassword
