import { createRef, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'
import { BasicButtonSizes } from '../button/basic-button'
import { Errors } from './form/errors'
import * as UI from '@/ui'

export type SubmitProps = { showError?: boolean } & Omit<UI.ButtonProps, 'type'>

export const Submit = ({
  children,
  className,
  errorMessage,
  showError = false,
  onClick,
  ...props
}: Omit<SubmitProps, 'uiComponent'>) => {
  const { errors, isSubmitting, isValidating, setErrors } = useFormikContext()
  const [disabled, setButtonDisabled] = useState<boolean>()

  const [isClicked, setIsClicked] = useState<boolean>(false)

  const numberOfErrors = isClicked ? Object.keys(errors).length : 0

  useEffect(() => {
    setButtonDisabled(numberOfErrors > 0)
  }, [numberOfErrors])

  const belowButtonRef = createRef<HTMLAnchorElement>()

  const [hasValidationError, setHasValidationError] = useState<boolean>()

  useEffect(() => {
    if (isSubmitting && isValidating && !isEmpty(errors)) {
      setHasValidationError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValidating])

  useEffect(() => {
    if (isClicked && !isValidating && (hasValidationError || errorMessage)) {
      setHasValidationError(false)
      belowButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, isValidating, hasValidationError, errorMessage])

  const click = (e: React.MouseEvent<HTMLButtonElement>) => {
    setErrors({})
    setIsClicked(true)
    onClick && onClick(e)
  }

  return (
    <UI.Block
      gap="xs"
      className={['xs', 'small', 'medium', 'xl', 'fit'].includes(props.size as BasicButtonSizes) ? 'w-fit' : 'w-full'}
    >
      <UI.Button
        type="submit"
        onClick={click}
        disabled={disabled}
        className={className}
        uiComponent="Form.Submit"
        isRunning={isSubmitting}
        {...(props as UI.ButtonProps)}
      >
        {children}
      </UI.Button>

      <div className="relative">
        <span className="absolute top-2 right-0 w-[200px]">
          {showError && <Errors message={errorMessage} isClicked={isClicked} />}
        </span>
      </div>
      <a ref={belowButtonRef}></a>
    </UI.Block>
  )
}

Submit.displayName = 'Form.Submit'

export default Submit
