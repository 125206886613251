import trustPilotData from '../../data/trustpilot.json'
import TPMicroHeader from './micro-header'
import TPMicro from './micro'
import TPMicroReview from './micro-review'
import TPMini from './mini'
import TPCompact from './compact'
import TPWithoutLabel from './without-label'

type TrustpilotBoxProps = {
  boxStyle: 'compact' | 'micro' | 'microReview' | 'mini' | 'microHeader' | 'withoutLabel'
  darkBg?: boolean
}

export interface TrustPilotData {
  label: string
  rating: number
  reviews: number
  url: string
}

export const TrustpilotBox = ({ boxStyle, darkBg = false }: TrustpilotBoxProps) => {
  return (
    <>
      {boxStyle === 'compact' && <TPCompact trustData={trustPilotData} darkBg={darkBg} />}
      {boxStyle === 'micro' && <TPMicro trustData={trustPilotData} darkBg={darkBg} />}
      {boxStyle === 'microHeader' && <TPMicroHeader trustData={trustPilotData} darkBg={darkBg} />}
      {boxStyle === 'microReview' && <TPMicroReview trustData={trustPilotData} />}
      {boxStyle === 'mini' && <TPMini trustData={trustPilotData} />}
      {boxStyle === 'withoutLabel' && <TPWithoutLabel trustData={trustPilotData} darkBg={darkBg} />}
    </>
  )
}

export default TrustpilotBox
