import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { liteClient as algoliasearch } from 'algoliasearch/lite'
import { InstantSearch, SearchBox } from 'react-instantsearch'
import type { SearchClient } from 'instantsearch.js'
import SearchResults from './search-results'
import SearchIcon from '@/images/icons/search_icon.svg?svgr'
import * as UI from '@/ui'

const ALGOLIA_APPLICATION_ID = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID
const ALGOLIA_SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY

if (!ALGOLIA_APPLICATION_ID || !ALGOLIA_SEARCH_API_KEY) {
  console.error('Missing search connection data')
}

const baseSearchClient = algoliasearch(ALGOLIA_APPLICATION_ID || '', ALGOLIA_SEARCH_API_KEY || '')
export const productsIndex = 'products-index'
export const blogsIndex = 'blogs-index'

export const searchClient: SearchClient = {
  ...baseSearchClient,
  search(requests) {
    if (requests.every(({ params }) => !params?.query || params.query.length < 2)) {
      // Return empty results if the query is empty or has less than 2 characters
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          processingTimeMS: 0,
          exhaustiveNbHits: true,
          query: '',
          params: '',
        })),
      })
    }

    return baseSearchClient.search(requests)
  },
}

export const AlgoliaSearch = ({
  mobileSearch,
}: {
  mobileSearch?: { searchOpen: boolean; setSearchOpen: (v: boolean) => void }
}) => {
  const router = useRouter()
  const [currentQuery, setCurrentQuery] = useState('')
  const [showResults, setShowResults] = useState(true)

  // Reset currentQuery when navigating away from search results page
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // If not navigating to a search results page, reset the query
      if (!url.startsWith('/search-results')) {
        setCurrentQuery('')
      }
    }

    // Subscribe to router events
    router.events.on('routeChangeStart', handleRouteChange)

    // Cleanup subscription on unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  if (!searchClient) {
    console.error('Missing search connection data')
    return null
  }

  function queryHook(query: string, search: (v: string) => void) {
    const input = query.trim()

    if (timerId) {
      clearTimeout(timerId)
    }

    setCurrentQuery(input)

    // Show results container when user starts typing
    if (input.length >= 2 && !showResults) {
      setShowResults(true)
    }

    timerId = setTimeout(() => search(input), 400)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && currentQuery.length >= 2) {
      e.preventDefault()
      setShowResults(false)

      if (mobileSearch?.setSearchOpen) {
        mobileSearch.setSearchOpen(false)
      }

      router.push(`/search-results?q=${encodeURIComponent(currentQuery)}`)
    }
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={productsIndex}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <UI.Block className="w-full px-10 max-md:pt-1 md:max-w-40 md:px-0 lg:max-w-56">
        <div className="relative">
          <SearchBox
            placeholder="Search"
            queryHook={queryHook}
            classNames={{
              form: 'relative group',
              input:
                'bg-selphGreen-600 h-8 text-selphWhite-500 w-full md:w-40 lg:max-w-56 lg:w-full pl-4 pr-12 border border-selphGreen-400 rounded-full placeholder-selphWhite-500 focus:outline-none focus:ring-0 hover:border-selphAmber-500 focus:border-selphAmber-500 transition-all duration-200',
              reset: 'hidden',
            }}
            submitIconComponent={() => (
              <span
                className="absolute inset-y-0 right-0 flex w-10 cursor-pointer items-center justify-center rounded-r-full border-t border-r border-b border-selphGreen-400 bg-selphOrange-500 transition-all duration-200 group-focus-within:border-selphAmber-500 group-hover:border-selphAmber-500 hover:border-selphGreen-400 hover:bg-selphAmber-500 group-focus-within:hover:border-selphAmber-500 focus:outline-none"
                onClick={(e) => {
                  if (currentQuery.length === 0) {
                    e.preventDefault()
                    router.push('/search-results')
                  }

                  setShowResults(false)
                  if (mobileSearch?.setSearchOpen) {
                    mobileSearch.setSearchOpen(false)
                  }

                  router.push(`/search-results?q=${encodeURIComponent(currentQuery)}`)
                }}
              >
                <SearchIcon className="size-5 text-selphWhite-500" />
              </span>
            )}
            loadingIconComponent={() => (
              <span className="absolute inset-y-0 right-12 flex w-10 items-center justify-center">
                <UI.Spinner size="small" />
              </span>
            )}
            autoFocus={mobileSearch?.searchOpen}
            onKeyDown={handleKeyDown}
          />
        </div>

        {showResults && (
          <div className="absolute top-24 z-30 flex w-full max-md:mr-6 max-md:-ml-10 md:relative md:top-12 md:-ml-40 md:w-lg">
            <SearchResults mobileSearch={mobileSearch} />
          </div>
        )}
      </UI.Block>
    </InstantSearch>
  )
}

export default AlgoliaSearch

let timerId: ReturnType<typeof setTimeout>
