import { useFormikContext } from 'formik'
import { type FormikValues } from 'formik/dist/types'
import { filter } from 'lodash-es'
import { createRef, useEffect, useRef, useState } from 'react'
import { PostCodeLookupAddress } from '../../../pages/api/post-code-lookup.api'
import { usePostCodeLookup } from '../../../services/post-code-lookup'
import { ButtonThemes } from '../button'
import * as UI from '../index'
import { Breakpoints } from '../use-ui'
import { schema } from './validation'

export type AddressProps = {
  name: string
  gridSize?: Breakpoints<UI.GridSizes>
  theme?: ButtonThemes
}

export type AddressValues = {
  address1: string
  address2?: string
  city: string
  postcode: string
  postcodeSearch?: string
  manualAddressInput?: boolean
  validateSearch?: boolean
}

export const AddressInitialValues = {
  address1: '',
  city: '',
  postcode: '',
  postcodeSearch: '',
  validateSearch: false,
}

export const AddressValidationSchema = schema({
  address1: {
    label: 'Address 1',
    type: 'string',
    required: true,
  },
  city: {
    label: 'City',
    type: 'string',
    required: true,
  },
  postcode: {
    label: 'Postcode',
    type: 'string',
    required: true,
  },
  postcodeSearch: {
    label: 'Postcode',
    type: 'string',
    required: true,
    min: 3,
    depends: {
      name: 'validateSearch',
      value: true,
    },
  },
  validateSearch: {
    label: 'Validate Search',
    type: 'boolean',
    required: false,
  },
})

export const Address = function <Values extends FormikValues = FormikValues>({ name }: AddressProps) {
  const { values, setFieldValue } = useFormikContext<Values>()
  const { clearAddresses } = usePostCodeLookup()

  const [isManual, setIsManual] = useState(false)
  const [addressSelected, setAddressSelected] = useState<boolean>()

  useEffect(() => {
    const address: AddressValues = values[name]
    const hasAddress = address.address1 !== '' && address.city !== '' && address.postcode !== ''

    hasAddress ? setAddressSelected(true) : setAddressSelected(false)
  }, [values, name])

  useEffect(() => {
    const address: AddressValues = values[name]
    const hasAddress = address.address1 !== '' && address.city !== '' && address.postcode !== ''

    if (hasAddress) {
      setIsManual(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFieldValue(`${name}.validateSearch`, addressSelected ? false : true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSelected])

  const selectAddress = async (address: PostCodeLookupAddress) => {
    await setFieldValue(`${name}.address1`, address.line_1, true)
    await setFieldValue(
      `${name}.address2`,
      filter([address.line_2, address.line_3, address.line_4], (v) => v !== '').join(', '),
    )
    await setFieldValue(`${name}.city`, address.town_or_city, true)
    await setFieldValue(`${name}.postcode`, values[name].postcodeSearch, true)

    setAddressSelected(true)
    clearAddresses()
  }

  return (
    <>
      {isManual && (
        <>
          <ManualShippingDetailsForm name={name} />

          <div className="mt-1 justify-self-end">
            <UI.Button
              type="text"
              onClick={() => {
                setIsManual(false)
              }}
            >
              <span className="font-light text-selphBlack underline transition-all duration-300 hover:text-selphAmber-500 hover:decoration-selphAmber-500">
                Search by postcode
              </span>
            </UI.Button>
          </div>
        </>
      )}

      {!isManual && (
        <>
          <PostCodeSearchForm
            name={name}
            addressSelected={addressSelected}
            onRequestManualInput={() => {
              setIsManual(true)
              setFieldValue(`${name}.validateSearch`, false)
            }}
            onPostcodeSelected={(address) => {
              selectAddress(address)
              setIsManual(true)
            }}
          />
        </>
      )}
    </>
  )
}

export default Address

interface PostcodeSearchFormProps extends AddressProps {
  addressSelected: boolean | undefined
  onRequestManualInput: (message?: string) => void
  onPostcodeSelected: (address: PostCodeLookupAddress) => void
}

const PostCodeSearchForm = function <Values extends FormikValues = FormikValues>({
  name,
  addressSelected,
  onRequestManualInput,
  onPostcodeSelected,
}: PostcodeSearchFormProps) {
  const { addresses: results, error, isLoading, lookupPostcode, clearAddresses } = usePostCodeLookup()
  const { values, setErrors } = useFormikContext<Values>()
  const findAddressRef = createRef<HTMLDivElement>()
  const searched = useRef(false)

  const [errorMsg, setErrorMsg] = useState<string>('')

  const handleBlur = () => {
    if (!searched.current && !addressSelected) {
      setErrorMsg('You must search your address with a postcode.')
    }
  }

  const lookUpAddress = (name: string) => {
    const postalcode = values[name]['postcodeSearch']

    if (postalcode.length > 2) {
      setErrorMsg('')
      lookupPostcode(postalcode)
      setErrors({})
    }
  }

  return (
    <>
      {!results && (
        <>
          <UI.Block gap="xs">
            <div className="flex max-w-md items-start gap-x-5">
              <UI.Form.Text
                name={`${name}.postcodeSearch`}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    lookUpAddress(name)
                  }
                }}
                boxSize="full"
              >
                Postcode
              </UI.Form.Text>
              <UI.Form.Button
                name={`${name}.findButton`}
                size={{ default: 'small', sm: 'medium' }}
                color="outline"
                onClick={() => lookUpAddress(name)}
                onMouseDown={() => (searched.current = true)}
                isRunning={isLoading}
                rootClassName="mt-7"
              >
                Find
              </UI.Form.Button>
            </div>

            {error?.message && <UI.Form.Error>{error?.message || errorMsg}</UI.Form.Error>}

            <button className="inline-block min-w-26 text-end" onClick={() => onRequestManualInput()}>
              <span className="font-light text-selphBlack underline transition-all duration-300 hover:text-selphAmber-500 hover:decoration-selphAmber-500">
                Enter manually
              </span>
            </button>
          </UI.Block>
        </>
      )}

      {results && (
        <>
          {Array.isArray(results) && !!results.length && <div>Choose your address:</div>}

          <div
            className="block h-36 w-full overflow-x-auto rounded-xl bg-selphWhite-300 p-2 sm:text-sm"
            ref={findAddressRef}
          >
            <UI.Block gap="small">
              {results.map((address) => (
                <UI.Button
                  key={address.formatted_address.join(',')}
                  type="text"
                  onClick={() => {
                    onPostcodeSelected(address)
                  }}
                  className="rounded px-2 py-1 text-left hover:bg-selphGreen-400 hover:text-selphWhite-500"
                >
                  {filter(address.formatted_address, (v) => v !== '').join(', ')}
                </UI.Button>
              ))}
            </UI.Block>
          </div>

          <div className="mt-1 flex justify-between">
            <UI.Button
              type="text"
              onClick={() => {
                onRequestManualInput()
              }}
            >
              <span className="text-sm font-light text-selphBlack underline transition-all duration-300 hover:text-selphAmber-500 hover:decoration-selphAmber-500">
                Enter address manually
              </span>
            </UI.Button>

            <UI.Button
              type="text"
              onClick={() => {
                clearAddresses()
                setErrors({})
                searched.current = false
              }}
            >
              <span className="text-sm font-light text-selphBlack underline transition-all duration-300 hover:text-selphAmber-500 hover:decoration-selphAmber-500">
                Search again
              </span>
            </UI.Button>
          </div>
        </>
      )}
    </>
  )
}

const ManualShippingDetailsForm = ({ name }: { name: string }) => {
  return (
    <>
      <UI.Form.Text name={`${name}.address1`}>Address 1</UI.Form.Text>
      <UI.Form.Text name={`${name}.address2`}>Address 2</UI.Form.Text>
      <UI.Form.Text name={`${name}.city`}>City</UI.Form.Text>
      <UI.Form.Text name={`${name}.postcode`}>Postcode</UI.Form.Text>
    </>
  )
}
