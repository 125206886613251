import { Fragment, useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import { cn } from '../helpers/tailwind'
import TrustpilotBox from './Trustpilot/trustpilot-box'
import { ProductLink } from './product/product-link'
import { useBasket } from './checkout/use-basket'
import { useUser } from './user/use-user'
import AlgoliaSearch from './Algolia/algolia-search'
import { linkHoverStyle } from './footer'
import UserIcon from '@/images/icons/user_icon.svg?svgr'
import CartIcon from '@/images/icons/cart_icon.svg?svgr'
import SearchIcon from '@/images/icons/search_icon.svg?svgr'
import SelphLogo from '@/images/logo.svg?svgr'
import PopularTest from '@/images/product/qfit_bowel_cancer_test_box_thumb.png'
import { ProductType } from '@/gql'
import * as UI from '@/ui'

export const Header = ({ darkBreadcrumbBg = false }: { darkBreadcrumbBg?: boolean }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const { numberOfLineItems, destroyBasket } = useBasket()
  const { isLoggedIn, logout } = useUser()

  return (
    <>
      {/* Mobile Menu */}
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-selphBlack/75" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="-translate-x-full opacity-0"
            >
              <DialogPanel className="relative flex w-full max-w-xs flex-1 flex-col bg-selphGreen-500 pt-5 pb-4">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-white focus:outline-none focus:ring-inset"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-selphWhite-500" aria-hidden="true" />
                  </button>
                </div>

                <div className="flex flex-shrink-0 items-center px-4">
                  <UI.Link to="root">
                    <SelphLogo width={100} className="text-selphWhite-500" />
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>

                <UI.Block className="mt-5 h-0 flex-1 overflow-y-auto">
                  <UI.Block gap="small" className="px-2">
                    <span className="block text-selphOrange-500">Tests</span>
                    {navigation.desktopPages.map((page) => (
                      <UI.Link
                        key={page.name}
                        to={page.to}
                        className="flex items-center font-light"
                        onClick={() => setSidebarOpen(false)}
                        color={'white'}
                      >
                        {({ active }) => (
                          <div
                            className={cn(
                              active && 'cursor-default border border-l-selphOrange-500',
                              !active && 'border-l-transparent',
                              'w-full border-0 border-l-2 pl-2 text-lg',
                            )}
                          >
                            <span className={cn(!active && 'link-hover-selphAmber-500')}>{page.name}</span>
                          </div>
                        )}
                      </UI.Link>
                    ))}
                  </UI.Block>
                  <UI.Block gap="small" className="px-2">
                    <span className="block text-selphOrange-500">Explore More</span>
                    {navigation.mobilePages.map((page) => (
                      <UI.Link
                        key={page.name}
                        to={page.to}
                        className="flex items-center font-light"
                        onClick={() => setSidebarOpen(false)}
                        color={'white'}
                      >
                        {({ active }) => (
                          <div
                            className={cn(
                              active && 'cursor-default border border-l-selphOrange-500',
                              !active && 'border-l-transparent',
                              'w-full border-0 border-l-2 pl-2 text-lg',
                            )}
                          >
                            <span className={cn(!active && 'link-hover-selphAmber-500')}>{page.name}</span>
                          </div>
                        )}
                      </UI.Link>
                    ))}
                  </UI.Block>
                  <UI.Block gap="small">
                    <span className="block px-2 text-selphOrange-500">Popular Tests</span>

                    <div className="mx-auto max-w-xs overflow-hidden">
                      <div className="relative h-32 w-full">
                        <ProductLink
                          slug="q-fit-bowel-cancer-test"
                          type={ProductType.LabTest}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <UI.Image
                            src={PopularTest}
                            alt="Popular test"
                            fill
                            placeholder="blur"
                            sizes="320px"
                            className="object-cover object-center"
                          />
                        </ProductLink>
                      </div>

                      <div className="h-full px-2 py-2">
                        <UI.Block gap="small">
                          <ProductLink
                            slug="q-fit-bowel-cancer-test"
                            type={ProductType.LabTest}
                            onClick={() => setSidebarOpen(false)}
                            color="white"
                          >
                            <span className="font-semibold underline hover:text-selphAmber-500 hover:decoration-selphAmber-500">
                              qFIT Bowel Cancer Screening Test
                            </span>
                          </ProductLink>

                          <UI.Paragraph size="small" color="white">
                            Got gut symptoms or just want to screen for bowel cancer? The qFIT test - used in the NHS
                            and the Bowel Cancer Screening Programme - picks up blood in the stool which can be caused
                            by bowel cancer.
                          </UI.Paragraph>
                        </UI.Block>
                      </div>
                    </div>
                  </UI.Block>
                </UI.Block>
              </DialogPanel>
            </TransitionChild>

            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Mobile Algolia Search */}
      <Transition show={searchOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSearchOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-selphBlack/75" />
          </TransitionChild>

          <div className="fixed inset-y-0 right-0 left-10 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <DialogPanel className="relative flex w-full flex-1 flex-col bg-selphGreen-600 pt-5 pb-4">
                <div className="absolute top-0 -left-10 py-3">
                  <button
                    type="button"
                    className="flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-white focus:outline-none focus:ring-inset"
                    onClick={() => setSearchOpen(false)}
                  >
                    <span className="sr-only">Close searchbar</span>
                    <XMarkIcon className="h-6 w-6 text-selphWhite-500" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <AlgoliaSearch mobileSearch={{ searchOpen, setSearchOpen }} />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Main Menu */}
      <div id="navbar">
        <div className="bg-selphGreen-600 py-2">
          <div className="mx-auto flex h-5 w-full max-w-7xl items-baseline justify-center px-2 sm:px-4 md:h-8 md:justify-between md:px-6">
            <div className="md:flex-1">
              {/* Trustbox */}
              <TrustpilotBox boxStyle="microHeader" darkBg />
            </div>

            {/* Algolia Search */}
            <div className="flex h-8 justify-center max-md:hidden lg:flex-1">
              <AlgoliaSearch />
            </div>

            {/* Menu Items */}
            <div className="flex justify-end gap-x-2 max-md:hidden md:flex-1 lg:gap-x-3">
              {...navigation.mobilePages.map((page) => (
                <UI.Link
                  key={page.name}
                  to={page.to}
                  size="small"
                  highlight="none"
                  color="white"
                  className={`${linkHoverStyle} whitespace-nowrap`}
                >
                  {page.name}
                </UI.Link>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-selphGreen-500 py-2">
          <div className="mx-auto flex max-w-7xl items-center justify-between gap-x-2 gap-y-2 px-4 md:px-6">
            <div className="flex gap-x-2">
              {/* Menu Icon */}
              <div className="z-10 flex items-center md:hidden">
                <button
                  type="button"
                  className="text-selphWhite-500 hover:ring-2 hover:ring-selphAmber-500 focus:ring-2 focus:ring-selphAmber-500 focus:outline-none md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="-ml-1 h-6 w-6 stroke-1" aria-hidden="true" />
                </button>
              </div>

              {/* Logo */}
              <div className="flex items-center justify-center md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <UI.Link to="root">
                    <SelphLogo className="mx-2 w-16 text-selphWhite-500 md:w-20 lg:w-24" />
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>
              </div>
            </div>

            {/* Navigation */}
            <div className="hidden flex-wrap items-center justify-center px-5 pb-1 md:flex md:space-x-5 lg:justify-around">
              {navigation.desktopPages.map((item) => (
                <UI.Link
                  key={item.name}
                  to={item.to}
                  color="white"
                  className={`text-base whitespace-nowrap lg:text-xl ${linkHoverStyle}`}
                >
                  {item.name}
                </UI.Link>
              ))}
            </div>

            <div className="flex items-center justify-end gap-x-2">
              {/* Profile dropdown */}
              {isLoggedIn ? (
                <Menu as="div" className="relative z-20">
                  <MenuButton className="flex cursor-pointer rounded-full border border-selphOrange-500 bg-selphOrange-500 p-1.5 text-sm hover:border-selphAmber-500 hover:bg-selphAmber-500 focus:outline-none md:p-2">
                    <span className="sr-only">Open user menu</span>

                    <div className="flex items-center stroke-2 text-selphWhite-500">
                      <UserIcon className="h-6 w-6 shrink-0 rounded-full opacity-90 lg:h-8 lg:w-8" />
                    </div>
                  </MenuButton>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-20 mt-2 w-48 origin-top-right overflow-hidden rounded-b-xl bg-selphWhite-100 shadow-lg focus:outline-none">
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          <UI.Link
                            to={item.to}
                            color="none"
                            className="block px-4 py-2 text-sm text-selphGrey-800 hover:bg-selphGreen-500 hover:text-selphWhite-500"
                          >
                            <span>{item.name}</span>
                          </UI.Link>
                        </MenuItem>
                      ))}

                      <UI.Divider margin="none" />

                      <MenuItem>
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-selphGrey-800 hover:bg-selphGreen-500 hover:text-selphWhite-500"
                          onClick={(e) => {
                            e.preventDefault()
                            logout()
                            destroyBasket()
                          }}
                        >
                          Logout
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              ) : (
                <UI.Link
                  color="white"
                  to="account"
                  className="flex items-center rounded-full border border-selphWhite-500 p-1.5 text-sm hover:border-selphAmber-500 hover:text-selphAmber-500 md:p-2"
                >
                  <UserIcon className="h-6 w-6 shrink-0 rounded-full fill-transparent stroke-1 lg:h-8 lg:w-8" />
                </UI.Link>
              )}

              {/* Basket */}
              <div className="group hover:boarder-selphAmber-500 relative rounded-full border border-selphWhite-500 p-1.5 hover:border-selphAmber-500 focus:outline-none md:p-2">
                <UI.Link to="cart">
                  <CartIcon
                    className="h-6 w-6 text-selphWhite-500 group-hover:text-selphAmber-500 lg:h-8 lg:w-8"
                    aria-hidden="true"
                  />

                  <div className="absolute top-4 -right-2" title="Cart Items">
                    <div className="flex h-5 w-5 rounded-full bg-selphAmber-500 text-sm text-selphWhite-500">
                      <span className="m-auto my-auto">{numberOfLineItems}</span>
                    </div>
                  </div>
                </UI.Link>
              </div>

              {/* Algolia search */}
              <div className="z-10 flex items-center md:hidden">
                <button
                  type="button"
                  className="rounded-full border border-selphWhite-500 p-1.5 text-selphWhite-500 hover:border-selphAmber-500 hover:ring-selphAmber-500 focus:outline-none md:hidden"
                  onClick={() => setSearchOpen(true)}
                >
                  <span className="sr-only">Open searchbar</span>
                  <SearchIcon className="h-6 w-6 stroke-1" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cn(
            darkBreadcrumbBg
              ? 'border border-t-[1.5px] border-selphGreen-500 border-t-selphGreen-400 bg-selphGreen-500'
              : '',
          )}
        >
          <div className="mx-auto mt-2 max-w-7xl px-4 md:px-6">
            <UI.Breadcrumb size={{ default: 'sm', md: 'md' }} darkBg={darkBreadcrumbBg} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

const userNavigation = [
  { name: 'My Dashboard', to: 'account' },
  { name: 'My Orders', to: 'accountOrders' },
  { name: 'My Results History ', to: 'accountResultsHistory' },
  { name: 'Account Settings', to: 'accountSettings' },
]

const navigation = {
  desktopPages: [
    { name: 'All Tests', to: 'tests' },
    { name: 'Gut Health', to: 'gutHealthTests' },
    { name: "Men's Health", to: 'mensHealthTests' },
    { name: "Women's Health", to: 'womensHealthTests' },
    { name: 'Metabolic Health', to: 'metabolicHealthTests' },
    { name: 'General Health', to: 'generalHealthTests' },
  ],
  mobilePages: [
    { name: 'Activate Test', to: 'activateKit' },
    { name: 'Learn', to: 'learn' },
    { name: 'Help', to: 'help' },
    { name: 'Contact Us', to: 'contact' },
  ],
}
