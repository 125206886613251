import { useState } from 'react'
import { useMailchimp } from '../../user/use-mailchimp'
import * as UI from '@/ui'

export const EmailSignUp = ({ darkTheme }: { darkTheme?: boolean }) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const { signUp, success, isReady } = useMailchimp()

  return (
    <>
      <UI.Form.Form
        initialValues={{ emailAddress: '' }}
        validationSchema={UI.Form.validation.schema({
          emailAddress: { label: 'This', type: 'string', required: true, criteria: 'emailAddress' },
        })}
        onSubmit={async (values, actions) => {
          await signUp({ email: values.emailAddress, source: 'footer', tags: ['newsletter'] })
          setShowSignUpModal(true)
          actions.resetForm()
        }}
      >
        <div className="flex w-full max-w-lg flex-col gap-x-5 gap-y-2 sm:flex-row">
          <UI.Form.Text
            boxSize={'full'}
            name="emailAddress"
            placeholder="Email Address"
            theme={darkTheme ? 'dark' : 'light'}
            containerClassName="grow"
          />
          <div className="flex justify-end">
            <UI.Form.Submit size="medium">Sign Up</UI.Form.Submit>
          </div>
        </div>
      </UI.Form.Form>

      <UI.Modal show={showSignUpModal && isReady} onClose={() => setShowSignUpModal(!showSignUpModal)}>
        <UI.Block gap="xs">
          {success ? (
            <>
              <UI.Heading size={{ default: 'xxs', sm: 'xs' }}>Thank you!</UI.Heading>
              <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
                You&apos;ve joined the Selph community and will get email updates from us.
              </UI.Paragraph>
            </>
          ) : (
            <>
              <UI.Heading size={{ default: 'xxs', sm: 'xs' }}>Something went wrong</UI.Heading>
              <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
                Sorry, there was a problem signing you up. Please try again.
              </UI.Paragraph>
            </>
          )}
        </UI.Block>
      </UI.Modal>
    </>
  )
}

export default EmailSignUp
