import { useUi, type Breakpoints } from '../use-ui'
import { SolidButtonTypes } from './solid-button'
import { ButtonPropsShared } from './index'

export type IconButtonColors = 'primary'
export type IconButtonSizes = 'small' | 'medium' | 'large' | 'xl'

export type IconButtonProps = {
  color?: IconButtonColors
  size?: Breakpoints<IconButtonSizes>
  textSize?: never
  type?: SolidButtonTypes
} & ButtonPropsShared

export const IconButton = ({
  color = 'primary',
  size = 'large',
  disabled,
  className,
  icon,
  ...htmlProps
}: Omit<IconButtonProps, 'errorMessage'>) => {
  const colors = {
    default: `${disabled ? `opacity-70 ${disabled && 'cursor-not-allowed'}` : 'cursor-pointer'}`,
    primary:
      'border border-selphBlack hover:border-selphAmber-500 text-selphBlack hover:bg-selphAmber-500 hover:text-selphWhite-500 transition duration-200',
  }

  const sizes = {
    default: '',
    small: 'p-1 size-8 rounded-lg', // Tailwind: sm:p-1 sm:size-8 sm:rounded-lg md:p-1 md:size-8 md:rounded-lg lg:p-1 lg:size-8 lg:rounded-lg
    medium: 'p-2 size-10 rounded-xl', // Tailwind: sm:p-2 sm:size-10 sm:rounded-xl md:p-2 md:size-10 md:rounded-xl lg:p-2 lg:size-10 lg:rounded-xl
    large: 'p-2 size-12 rounded-xl', // Tailwind: sm:p-2 sm:size-12 sm:rounded-xl md:p-2 md:size-12 md:rounded-xl lg:p-2 lg:size-12 lg:rounded-xl
    xl: 'p-2 size-14 rounded-xl', // Tailwind: sm:p-2 sm:size-14 sm:rounded-xl md:p-2 md:size-14 md:rounded-xl lg:p-2 lg:size-14 lg:rounded-xl
  }

  const ui = useUi({
    styles: {
      color: { options: colors, selected: color },
      size: { options: sizes, selected: size },
    },
    name: 'IconButton',
    className: `${className}  `,
  })

  const Icon = icon
  return (
    <button disabled={disabled} {...htmlProps}>
      {Icon && <Icon className={`${ui.className} `} />}
    </button>
  )
}

export default IconButton
