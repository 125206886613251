import TPLogo from '/public/images/TP-Logo.svg?svgr'
import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import { TrustPilotData } from './trustpilot-box'
import * as UI from '@/ui'

export const TPMini = ({ trustData }: { trustData: TrustPilotData }) => {
  return (
    <div className="font-helvetica mx-auto">
      <a target="_blank" href={trustData.url} className="flex w-full items-center justify-center">
        <UI.Block gap="small" display="flexCol">
          <span title="Trustpilot">
            <TPLogo width="140" />
            <span className="sr-only">Trustpilot Logo</span>
          </span>

          <span title={`${trustData.rating} out of five star rating on Trustpilot`}>
            <FiveStarsIcon width="236" height="40" className="-ml-2.5" />
            <span className="sr-only">Five Star Review</span>
          </span>

          <div className="font-helvetica ml-0.5 flex text-sm">
            <div className="flex">
              <span className="mr-1 font-normal">TrustScore</span>
              <span className="font-semibold">{trustData.rating.toLocaleString()}</span>
            </div>

            <span className="mx-1.5">|</span>

            <div className="flex">
              <span className="mr-1 font-semibold">{trustData.reviews.toLocaleString()}</span>
              <span className="font-normal">reviews</span>
            </div>
          </div>
        </UI.Block>
      </a>
    </div>
  )
}

export default TPMini
