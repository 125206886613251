import { Field, useField, useFormikContext } from 'formik'
import { Fragment, useEffect } from 'react'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type TextPropsCommon, boxSizes } from './index'
import CircleCheckIcon from '@/images/icons/circleCheckIcon.svg?svgr'
import AlertCircleIcon from '@/images/icons/alertCircleIcon.svg?svgr'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'
export type FormikTextProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & TextPropsCommon

export const FormikText = ({
  name,
  field = 'text',
  onKeyUp,
  errorMessage,
  boxSize = 'full',
  placeholder,
  depends,
  setIsHidden,
  theme = 'dark',
  ...props
}: FormikTextProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })
  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const themes = {
    light: cn(
      props.readOnly === 'readOnly'
        ? 'border-gray-400 text-gray-400 cursor-not-allowed ring-transparent placeholder-gray-400'
        : errorMessage
          ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphWhite-500'
          : meta.touched
            ? 'border-selphGreen-300 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphWhite-500'
            : 'border-selphGreen-100 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphWhite-500',
      'text-selphWhite-500 bg-transparent',
    ),
    dark: cn(
      props.readOnly === 'readOnly'
        ? 'border-gray-400 text-gray-400 cursor-not-allowed ring-transparent placeholder-gray-400 bg-gray-100'
        : errorMessage
          ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphRed-400 bg-transparent text-selphRed-400'
          : meta.touched
            ? 'border-selphGreen-400 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphGreen-400 bg-transparent'
            : 'border-selphWhite-600 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphBlack bg-transparent ',
    ),
  }

  const { className } = useUi({
    styles: {
      theme: { options: themes, selected: theme },
    },
    name: 'Form.Text',
    className: cn('focus:outline-hidden sm:text-sm rounded-full w-full border', props.className),

    //       : errorMessage
    //         ? 'border-selphRed-400'
    //         : meta.touched && 'border-green-500 '
    //   } focus:ring-selphAmber-500  `,
  })

  const containerUi = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Text.Container',
  })

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <Fragment>
      <div className={containerUi.className}>
        <Field name={name} type={field} onKeyUp={keyup} placeholder={placeholder} {...props} className={className} />

        {errorMessage && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <AlertCircleIcon
              className={cn(theme === 'dark' ? 'text-selphRed-400' : 'text-selphRed-400', 'size-5')}
              aria-hidden="true"
            />
          </div>
        )}

        {(meta.touched || props?.readOnly) && !errorMessage && (
          <CircleCheckIcon
            className="pointer-events-none absolute top-2.5 right-3 h-5 w-5 text-selphGreen-400"
            aria-hidden="true"
          />
        )}
      </div>

      {errorMessage &&
        (theme === 'dark' ? (
          <UI.Form.Error>{errorMessage}</UI.Form.Error>
        ) : (
          <UI.Form.Error className="max-w-fit rounded bg-selphWhite-100/60 px-2 py-0.5">{errorMessage}</UI.Form.Error>
        ))}
    </Fragment>
  )
}

FormikText.displayName = 'Form.Text.FormikText'

export default FormikText
