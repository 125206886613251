import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import ActionButton from '@/images/icons/actionsButtonLarge_icon.svg?svgr'
import { cn } from '@/helpers/tailwind'

export type ModalProps = {
  children: React.ReactNode
  show: boolean
  onClose: () => void
  title?: string
  color?: 'light' | 'dark' | 'none'
  disableOutsideClose?: boolean
  container?: boolean
}

const bgColors = {
  none: '',
  light: 'bg-selphWhite-100',
  dark: 'bg-selphGrey-200',
}

export const Modal = ({ title, color, show, onClose, children, disableOutsideClose, container = true }: ModalProps) => {
  const bgColor = bgColors[color || 'light']

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        open={show}
        onClose={disableOutsideClose ? () => null : onClose}
        onKeyDown={(e) => {
          if (disableOutsideClose && e.key === 'Escape') {
            onClose()
          }
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-selphBlack/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center max-sm:pt-20 sm:items-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={cn(bgColor, 'relative transform overflow-visible rounded-xl shadow-xl transition-all', {
                  'p-4 py-5 text-left sm:w-full sm:max-w-fit sm:p-10': container,
                })}
              >
                {/* For mobile */}
                <div className="absolute -top-15 left-0 w-full text-center sm:hidden">
                  <button
                    type="button"
                    className={`rounded-full text-selphWhite-500 ring-1 ring-selphWhite-500 transition-all duration-300 hover:bg-selphOrange-500 hover:ring-selphOrange-500`}
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <ActionButton className="h-12 w-12" aria-hidden="true" />
                  </button>
                </div>

                {/* For desktop */}
                <div className="absolute -bottom-18 left-0 w-full text-center max-sm:hidden">
                  <button
                    type="button"
                    className="rounded-full text-selphWhite-500 ring-1 ring-selphWhite-500 transition-all duration-300 hover:bg-selphOrange-500 hover:ring-selphOrange-500"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <ActionButton className="h-12 w-12" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-0 sm:text-left">
                    {title && (
                      <DialogTitle
                        as="h3"
                        className="mb-2 text-3xl leading-10 font-normal text-selphBlack md:text-[42px]"
                      >
                        {title}
                      </DialogTitle>
                    )}
                    {children}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.displayName = 'Modal'

export default Modal
