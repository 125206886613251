import { Highlight, useInstantSearch } from 'react-instantsearch'
import { Hit as AlgoliaHit } from 'instantsearch.js/es/types'
import ProductLink from '../product/product-link'
import { ProductType } from '../../gql'
import { cn } from '../../helpers/tailwind'
import { MobileSearch } from './search-results'
import * as UI from '@/ui'

export type AlgoliaProductRecord = {
  objectID: string
  name: string
  type: string
  slug: string
  price: number
  description: string
  showInListings: boolean
  featuredImage: {
    src: string
    name: string
  }
}

export type AlgoliaBlogRecord = {
  objectID: string
  title: string
  summary: string
  body: string
  path: string
  hero: {
    name: string
    src: string
  }
  publishedAt: string
  updatedAt: string
}

export type AlgoliaProductHitRecord = AlgoliaHit & AlgoliaProductRecord
export type AlgoliaBlogHitRecord = AlgoliaHit & AlgoliaBlogRecord

export type HitProps = {
  hit: AlgoliaProductHitRecord | AlgoliaBlogHitRecord
  mobileSearch?: MobileSearch
}

export const Hit = ({ hit, mobileSearch }: HitProps) => {
  const { setIndexUiState } = useInstantSearch()

  const isMobileSearchOpen = mobileSearch?.searchOpen

  const handleClick = () => {
    setIndexUiState((prevState) => ({
      ...prevState,
      query: '',
    }))

    isMobileSearchOpen && mobileSearch.setSearchOpen(false)
  }

  const productHit = isProductHit(hit)
  const blogHit = isBlogHit(hit)

  return (
    <article
      className={cn(
        isMobileSearchOpen ? 'bg-selphGreen-600 hover:bg-selphWhite-500' : 'bg-selphWhite-100 hover:bg-selphGreen-600',
        'group w-full cursor-pointer transition-all duration-200',
      )}
    >
      {productHit && (
        <ProductLink
          slug={hit.slug}
          type={hit.type as ProductType}
          color="none"
          onClick={handleClick}
          className="block w-full px-4 py-3"
        >
          <div className="flex items-center gap-3 md:gap-4">
            <div className="flex-shrink-0">
              <UI.Image
                src={`/images/product/${hit.featuredImage.src}`}
                alt={hit.featuredImage.name}
                priority
                width={48}
                height={48}
                className="hidden rounded-lg object-cover ring-1 ring-selphOrange-500 md:h-[56px] md:w-[56px] [@media(min-width:420px)]:block"
              />
            </div>

            <div className="min-w-0 flex-1">
              <UI.Block gap="xs" className="w-full">
                <span className="line-clamp-1 font-semibold">
                  <Highlight
                    hit={hit}
                    attribute="name"
                    classNames={{
                      highlighted: cn(
                        isMobileSearchOpen
                          ? 'bg-selphGreen-600 group-hover:bg-selphWhite-500'
                          : 'bg-selphWhite-100 group-hover:bg-selphGreen-600 group-hover:text-selphAmber-400',
                        'text-selphAmber-500  transition-all duration-200',
                      ),
                      nonHighlighted:
                        'text-selphWhite-500 md:text-selphBlack md:group-hover:text-selphWhite-500 group-hover:text-selphBlack',
                    }}
                  />
                </span>

                <span className="line-clamp-2 text-sm text-selphWhite-500 group-hover:text-selphBlack md:text-black md:group-hover:text-selphWhite-500">
                  {hit.description}
                </span>

                <span className="mt-1 text-sm font-medium text-selphWhite-500 group-hover:text-selphBlack md:text-black md:group-hover:text-selphWhite-500">
                  <UI.Currency value={hit.price} />
                </span>
              </UI.Block>
            </div>
          </div>
        </ProductLink>
      )}

      {blogHit && (
        <UI.Link
          color="none"
          to={['blogPost', { path: hit.path }]}
          className="block w-full px-4 py-3"
          onClick={handleClick}
        >
          <div className="flex items-center gap-3 md:gap-4">
            <div className="flex-shrink-0">
              <UI.Image
                src={`/images${hit.hero.src}`}
                alt={hit.hero.name}
                priority
                width={48}
                height={48}
                className="hidden rounded-lg object-cover ring-1 ring-selphOrange-500 md:h-[56px] md:w-[56px] [@media(min-width:420px)]:block"
              />
            </div>

            <div className="min-w-0 flex-1">
              <UI.Block gap="xs" className="w-full">
                <span className="line-clamp-1 font-semibold">
                  <Highlight
                    hit={hit}
                    attribute="title"
                    classNames={{
                      highlighted: cn(
                        isMobileSearchOpen
                          ? 'bg-selphGreen-600 group-hover:bg-selphWhite-500'
                          : 'bg-selphWhite-100 group-hover:bg-selphGreen-600 group-hover:text-selphAmber-400',
                        'text-selphAmber-500  transition-all duration-200',
                      ),
                      nonHighlighted:
                        'text-selphWhite-500 md:text-selphBlack md:group-hover:text-selphWhite-500 group-hover:text-selphBlack',
                    }}
                  />
                </span>

                <span className="line-clamp-2 text-sm text-selphWhite-500 group-hover:text-selphBlack md:text-black md:group-hover:text-selphWhite-500">
                  {hit.summary}
                </span>
              </UI.Block>
            </div>
          </div>
        </UI.Link>
      )}
    </article>
  )
}

export default Hit

const isProductHit = (hit: AlgoliaProductHitRecord | AlgoliaBlogHitRecord): hit is AlgoliaProductHitRecord => {
  return 'slug' in hit
}

const isBlogHit = (hit: AlgoliaProductHitRecord | AlgoliaBlogHitRecord): hit is AlgoliaBlogHitRecord => {
  return 'path' in hit
}
