import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'
import { cn } from '../../helpers/tailwind'

export const TPMicroHeader = ({ trustData, darkBg = false }: { trustData: TrustPilotData; darkBg?: boolean }) => {
  return (
    <div className="font-helvetica flex w-fit items-center justify-center">
      <a target="_blank" href={trustData.url} className="flex w-full items-center justify-center">
        <span
          className={cn(
            darkBg ? 'text-selphWhite-500' : 'text-selphGrey-900',
            'mr-1.5 flex self-center text-sm font-medium lg:text-base',
          )}
        >
          {trustData.label}
        </span>

        <span title={`${trustData.rating} out of five star rating on Trustpilot`}>
          <FiveStarsIcon className="w-[92px] md:w-[108px]" />
          <span className="sr-only">Five Star Review</span>
        </span>

        <div
          className={cn(
            darkBg ? 'text-selphWhite-500' : 'text-selphGrey-900',
            'mx-[7px] hidden w-[114px] items-baseline justify-between pt-0.5 text-sm tracking-normal min-[430px]:max-md:flex lg:flex',
          )}
        >
          <span className="font-medium">{trustData.reviews.toLocaleString()}</span>
          <span className="font-medium"> reviews on</span>
        </div>

        <span title="Trustpilot" className="ml-1">
          {darkBg ? (
            <TPLogoWhite width="80" className="mx-auto hidden min-[430px]:max-md:block lg:block" />
          ) : (
            <TPLogo width="80" className="mx-auto hidden min-[430px]:max-md:block lg:block" />
          )}

          <span className="sr-only">Trustpilot Logo</span>
        </span>

        <span
          className={cn(
            darkBg ? 'text-selphWhite-500' : 'text-selphGrey-900',
            'ml-1 hidden text-sm font-medium min-[300px]:max-[429px]:block md:max-lg:block',
          )}
        >
          {trustData.reviews.toLocaleString()} reviews
        </span>
      </a>
    </div>
  )
}

export default TPMicroHeader
