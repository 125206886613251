import Header from '../header'
import Footer from '../footer'
import { ContainerStyles } from '../ui/page'
import { useUi } from '../ui/use-ui'
import { BackgroundColour } from './layout'
import * as UI from '@/ui'

export type LayoutProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
  backgroundColour?: BackgroundColour
  thumbFooter?: boolean
  darkBreadcrumbBg?: boolean
}

export const LearnLayout = ({
  children,
  containerStyle,
  backgroundColour = 'offWhite',
  darkBreadcrumbBg,
}: LayoutProps) => {
  const backgroundColours = {
    offWhite: 'bg-selphWhite-500',
    lightishGrey: 'bg-selphGrey-100',
    none: '',
  }

  const { className } = useUi({
    styles: { layout: { options: backgroundColours, selected: backgroundColour } },
    name: 'Layout',
  })

  return (
    <div className={`relative flex min-h-screen flex-col overflow-clip ${className}`}>
      <Header darkBreadcrumbBg={darkBreadcrumbBg} />

      <UI.Page containerStyle={containerStyle}>
        <div className="relative mb-14">{children}</div>
      </UI.Page>

      <Footer />
    </div>
  )
}

export default LearnLayout
