import * as chrono from 'chrono-node'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { importDate, now } from '../../../../helpers/date'
import useUi from '../../use-ui'
import { DatePickerPropsCommon, boxSizes } from '.'
import CircleCheckIcon from '@/images/icons/circleCheckIcon.svg?svgr'
import AlertCircleIcon from '@/images/icons/alertCircleIcon.svg?svgr'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'
export type FormikDatePickerProps = DatePickerPropsCommon

const FormikDatePicker = ({
  name,
  label,
  field,
  dobFormatter,
  onKeyUp,
  errorMessage,
  boxSize = 'full',
  placeholder,
  ...props
}: FormikDatePickerProps) => {
  const { isSubmitting } = useFormikContext()
  const [fieldProps, meta, { setTouched, setValue }] = useField({ name })
  const [dateString, setDateString] = useState<string>(fieldProps.value || '')

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  useEffect(() => {
    const date = chrono.en.GB.parseDate(dateString)

    if (date) {
      setValue(date.toISOString())
    } else {
      setValue(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString])

  const yearsOld = useMemo(() => {
    const age = calculateAge(dateString)
    const msg = `${age} ${age === 1 ? 'year' : 'years'}`

    return msg
  }, [dateString])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    name: 'Form.DatePicker',
    className: cn(
      props?.readOnly === 'readOnly'
        ? 'border-gray-400 text-gray-400 cursor-not-allowed ring-transparent placeholder-gray-400 bg-gray-100'
        : errorMessage
          ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphRed-400 bg-transparent text-selphRed-400'
          : meta.touched
            ? 'border-selphGreen-400 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphGreen-400 bg-transparent'
            : 'border-selphWhite-600 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphBlack bg-transparent ',
      'focus:outline-hidden sm:text-sm rounded-full w-full border',
      props.className,
    ),
  })

  const containerUi = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.DatePicker.Container',
  })

  if (!fieldProps) {
    return <p>Loading...</p>
  }

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <>
      <div className={containerUi.className}>
        {label && <label className="label text-[0.825rem] font-light">{label}</label>}

        <Field
          type="text"
          name={name}
          placeholder={placeholder}
          value={dateString}
          onKeyUp={keyup}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setDateString(e.target.value)}
          {...props}
          className={className}
        />

        {errorMessage && (
          <AlertCircleIcon
            className="pointer-events-none absolute top-2.5 right-3 h-5 w-5 text-selphRed-400"
            aria-hidden="true"
          />
        )}

        {(meta.touched || props?.readOnly) && !errorMessage && (
          <CircleCheckIcon
            className="pointer-events-none absolute top-2.5 right-3 h-5 w-5 text-selphGreen-400"
            aria-hidden="true"
          />
        )}

        {fieldProps.value && (
          <p className="mt-1 text-sm text-selphGreen-400">
            {field === 'date' && (
              <>
                {chrono.en.GB.parseDate(dateString)?.toLocaleDateString(undefined, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </>
            )}

            {field === 'dateOfBirth' && (
              <>
                {!errorMessage && (
                  <>
                    {dobFormatter ? (
                      dobFormatter(yearsOld)
                    ) : (
                      <span className="ml-3">
                        You are <strong>{yearsOld}</strong>
                        {' old, born on: '}
                      </span>
                    )}
                  </>
                )}

                <strong>
                  {chrono.en.GB.parseDate(dateString)?.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </strong>
              </>
            )}
          </p>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikDatePicker.displayName = 'Form.Date.FormikDatePicker'

export default FormikDatePicker

export const calculateAge = (value: string): number => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const diffYears = Math.floor(now().endOf('day').diff(date, 'years').years)

  return diffYears
}
