import { useState } from 'react'
import { type Breakpoints } from '../../use-ui'
import { Label } from '../label'
import { HelpText } from '../help-text'
import { InputFile, type InputFileProps } from './input-file'
import { FormikFile, type FormikFileProps } from './formik-file'
import * as UI from '@/ui'

export type FileSize = 'medium' | 'full' | 'small'

export type FileProps = FilePropsFormik | FilePropsInput

export type FilePropsCommon = {
  name: string
  placeholder?: string
  errorMessage?: string

  boxSize?: Breakpoints<FileSize>
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'readOnly'>

export type FilePropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type FilePropsFormik = FormikFileProps &
  FilePropsShared & {
    type?: 'formik'
  }

export type FilePropsInput = InputFileProps &
  FilePropsShared & {
    type?: 'input'
  }

export const boxSizes = {
  default: 'block pr-10 focus:outline-hidden sm:text-sm rounded-md',
  full: 'w-full',
  small: 'max-w-32',
  medium: 'max-w-72',
}

export const File = ({ name, type = 'formik', helpTextAbove, helpTextBelow, children, ...props }: FileProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {type === 'formik' && <FormikFile name={name} setIsHidden={setIsHidden} {...props} />}
        {type === 'input' && <InputFile name={name} {...props} />}
        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

File.displayName = 'Form.File'

export default File
