import { Field, useField, useFormikContext } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type TextPropsCommon, boxSizes } from './index'
import EyeOffIcon from '@/images/icons/eyeOffIcon.svg?svgr'
import EyeIcon from '@/images/icons/eyeIcon.svg?svgr'
import * as UI from '@/ui'
import { cn } from '@/helpers/tailwind'

export type FormikPasswordProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & TextPropsCommon

export const FormikPassword = ({
  name,
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  ...props
}: FormikPasswordProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })

  const [passwordVisible, setPasswordVisible] = useState(false)

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    name: 'Form.Password',
    className: cn(
      props.readOnly === 'readOnly'
        ? 'border-gray-400 text-gray-400 cursor-not-allowed ring-transparent placeholder-gray-400 bg-gray-100'
        : errorMessage
          ? 'border-selphRed-500 focus:ring-selphAmber-500 focus:ring focus:border-transparent placeholder-selphRed-400 bg-transparent text-selphRed-400'
          : meta.touched
            ? 'border-selphGreen-400 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack text-selphGreen-400 bg-transparent'
            : 'border-selphWhite-600 focus:ring-selphAmber-500 focus:border-transparent placeholder-selphBlack  text-selphBlack bg-transparent ',
      'focus:outline-hidden sm:text-sm rounded-full w-full border',
      props.className,
    ),
  })

  const containerUi = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Password.Container',
  })

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  const iconColor =
    props?.readOnly === 'readOnly'
      ? 'text-gray-500'
      : errorMessage
        ? 'text-selphRed-400'
        : meta.touched
          ? 'text-selphGreen-400'
          : 'text-selphWhite-600'

  const SvgIcon = passwordVisible ? EyeOffIcon : EyeIcon

  return (
    <Fragment>
      <div className={containerUi.className}>
        <Field
          name={name}
          type={passwordVisible ? 'text' : 'password'}
          onKeyUp={keyup}
          placeholder={placeholder}
          {...props}
          className={`${className} pr-12`}
        />

        <button
          type="button"
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="absolute inset-y-0 right-0 flex items-center md:pl-18"
        >
          <div className="inset-y-0 right-0 flex items-center px-3 text-sm">
            <SvgIcon className={`h-6 w-6 ${iconColor}`} />
          </div>
        </button>
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </Fragment>
  )
}

FormikPassword.displayName = 'Form.Text.FormikPassword'

export default FormikPassword
