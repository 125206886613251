import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'

export const TPMicro = ({ trustData, darkBg }: { trustData: TrustPilotData; darkBg?: boolean }) => {
  return (
    <div className="font-helvetica mx-auto flex w-fit items-center justify-center">
      <a target="_blank" href={trustData.url} className="flex w-full items-center justify-center">
        <span
          className={`text-sm font-medium lg:text-base ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } mr-3.5 flex self-center`}
        >
          {trustData.label}
        </span>

        <span title={`${trustData.rating} out of five star rating on Trustpilot`}>
          <FiveStarsIcon className="mr-1 w-[92px] md:w-[108px]" />
          <span className="sr-only">Five Star Review</span>
        </span>

        <div
          className={`hidden w-[114px] text-sm md:flex ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } mx-[7px] items-baseline justify-between pt-0.5 tracking-normal`}
        >
          <span className="font-medium">{trustData.reviews.toLocaleString()}</span>
          <span className="font-medium"> reviews on</span>
        </div>

        <span title="Trustpilot">
          {darkBg ? (
            <TPLogoWhite width="80" className="mx-auto hidden md:block" />
          ) : (
            <TPLogo width="80" className="mx-auto hidden md:block" />
          )}
          <span className="sr-only">Trustpilot Logo</span>
        </span>

        <span
          className={`ml-1 text-sm font-medium max-[300px]:hidden md:hidden lg:text-base ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } `}
        >
          {trustData.reviews.toLocaleString()} reviews
        </span>
      </a>
    </div>
  )
}

export default TPMicro
