import { useUi, type Breakpoints } from './use-ui'

export type ParagraphSizes = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'none'
export type ParagraphColors =
  | 'darkBlue'
  | 'lightBlue'
  | 'midBlue'
  | 'darkGrey'
  | 'lightGrey'
  | 'hotPink'
  | 'orange'
  | 'amber'
  | 'offWhite'
  | 'white'
  | 'black'
  | 'inherit'
  | 'none'
export type ParagraphWeights = 'thin' | 'semiBold' | 'regular' | 'light'

export type ParagraphProps = {
  children: React.ReactNode
  className?: string
  size?: Breakpoints<ParagraphSizes>
  weight?: ParagraphWeights
  color?: Breakpoints<ParagraphColors>
}

export const Paragraph = ({
  className,
  weight = 'light',
  size = 'medium',
  color = 'black',
  children,
}: ParagraphProps) => {
  const sizes = {
    none: '',
    xxs: 'text-xs',
    xs: 'text-sm',
    small: 'text-base',
    medium: 'text-lg',
    large: 'text-xl',
    xl: 'text-2xl',
    xxl: 'text-4xl',
  }

  const colors = {
    none: '',
    darkBlue: 'text-selphBlue-600',
    midBlue: 'text-selphBlue-500',
    lightBlue: 'text-selphBlue-400',
    darkGrey: 'text-selphDarkGrey',
    lightGrey: 'text-selphLightGrey',
    hotPink: 'text-hotPink-500',
    black: 'text-selphBlack',
    white: 'text-selphWhite-500',
    amber: 'text-selphAmber-500',
    orange: 'text-selphOrange-500',
    offWhite: 'text-selphWhite-500',
    inherit: 'text-inherit',
  }

  const weights = {
    thin: 'font-thin',
    light: 'font-light',
    regular: 'font-normal',
    semiBold: 'font-semibold',
  }

  const ui = useUi({
    styles: {
      size: { options: sizes, selected: size },
      color: { options: colors, selected: color },
      weight: { options: weights, selected: weight },
    },
    name: 'Paragraph',
    className,
  })

  return <p className={ui.className}>{children}</p>
}

Paragraph.displayName = 'Paragraph'

export default Paragraph
