import { SVGProps } from 'react'
import EmailSignUp from './ui/form/email-signup'
import SelphLogo from '@/images/logo.svg?svgr'

import * as UI from '@/ui'

export const linkHoverStyle = 'hover:bg-selphOrange-500 transition duration-200 rounded p-1.5 font-medium text-lg'

const social = [
  {
    name: 'Facebook',
    to: 'facebook',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    to: 'instagram',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'TikTok',
    to: 'tiktok',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
        />
      </svg>
    ),
  },
]

const footerLinks = [
  { name: 'Health Tests', to: 'tests' },
  { name: 'Gut Health', to: 'gutHealthTests' },
  { name: 'General Health', to: 'generalHealthTests' },
  { name: 'Metabolic Health', to: 'metabolicHealthTests' },
  { name: "Men's Health", to: 'mensHealthTests' },
  { name: "Women's Health", to: 'womensHealthTests' },
  { name: 'Gift Cards', to: 'giftCard' },
  { name: 'The Team', to: 'team' },
  { name: 'Contact Us', to: 'contact' },
  { name: 'Partner Clinics', to: 'clinics' },
  { name: 'Learn', to: 'learn' },
  { name: 'Help', to: 'help' },
  { name: 'Service Status', to: 'serviceStatus' },
]

export const Footer = () => {
  return (
    <footer id="footer">
      <div className="w-full bg-selphGreen-500 px-4 py-10 md:px-6 md:py-16 lg:py-20">
        <UI.Grid
          gap="large"
          size={{ default: 1, md: 12 }}
          colVerticalAlign={{ default: 'top', md: 'middle' }}
          className="mx-auto max-w-7xl"
        >
          <UI.GridSpan size={{ default: 1, md: 6, lg: 5 }}>
            <UI.Block display="flexCol">
              <UI.Link to="root">
                <SelphLogo width={120} className="text-selphWhite-500" />
                <span className="sr-only">Home</span>
              </UI.Link>

              <UI.Block gap="small">
                <UI.Paragraph color="white" size="large">
                  Get tips on the better way to better health
                </UI.Paragraph>
                <EmailSignUp />
              </UI.Block>
            </UI.Block>
          </UI.GridSpan>

          <UI.GridSpan size={{ default: 0, md: 0, lg: 1 }}></UI.GridSpan>

          <UI.GridSpan size={{ default: 1, md: 6, lg: 6 }}>
            <div className="grid w-full grid-flow-col grid-rows-7 gap-5 lg:grid-rows-5">
              {footerLinks.map((item) => (
                <UI.Link color="white" key={item.name} to={item.to} className={linkHoverStyle}>
                  {item.name}
                </UI.Link>
              ))}
            </div>
          </UI.GridSpan>
        </UI.Grid>
      </div>
      <div className="w-full bg-selphGreen-600 px-4 py-6 max-lg:space-y-5 md:py-8">
        <UI.Grid
          size={{ default: 3, lg: 7 }}
          className="mx-auto max-w-7xl"
          colVerticalAlign={{ default: 'top', md: 'middle' }}
        >
          <UI.GridSpan size={{ default: 0, lg: 2 }}>
            <UI.Block gap="xs" className="hidden flex-1 lg:block">
              <UI.Paragraph color="white" size="xs">
                Selph Ltd, Reg. No. 12169438
              </UI.Paragraph>
              <UI.Paragraph color="white" size="xs">
                124 City Road, London, EC1V 2NX
              </UI.Paragraph>
            </UI.Block>
          </UI.GridSpan>
          <UI.GridSpan size={{ default: 2, lg: 3 }}>
            <div className="flex flex-1 flex-col gap-x-3 gap-y-3 md:flex-row lg:justify-center">
              <UI.Link color="white" to="terms-of-service" className={linkHoverStyle}>
                Terms of Service
              </UI.Link>
              <UI.Link color="white" to="privacy" className={linkHoverStyle}>
                Privacy Policy
              </UI.Link>
              <UI.Link color="white" to="cookie" className={linkHoverStyle}>
                Cookie Policy
              </UI.Link>
            </div>
          </UI.GridSpan>
          <UI.GridSpan size={{ default: 1, lg: 2 }}>
            <div className="flex flex-1 justify-end gap-x-2">
              {social.map((item) => (
                <UI.Link key={item.name} to={item.to} color="white">
                  <span className="sr-only">{item.name}</span>
                  <item.icon
                    className="size-10 rounded-full border border-selphGreen-300 p-2 transition duration-200 hover:bg-selphOrange-500"
                    aria-hidden="true"
                  />
                </UI.Link>
              ))}
            </div>
          </UI.GridSpan>
        </UI.Grid>
        <UI.Block gap="xs" className="px-2 lg:hidden">
          <UI.Paragraph color="white" size="xs">
            Selph Ltd, Reg. No. 12169438
          </UI.Paragraph>
          <UI.Paragraph color="white" size="xs">
            124 City Road, London, EC1V 2NX
          </UI.Paragraph>
        </UI.Block>
      </div>
    </footer>
  )
}

export default Footer
