import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'
import { cn } from '../../helpers/tailwind'

export const TPWithoutLabel = ({ trustData, darkBg = false }: { trustData: TrustPilotData; darkBg?: boolean }) => {
  return (
    <div className="font-helvetica flex w-fit items-center justify-center">
      <a target="_blank" href={trustData.url} className="flex w-full flex-wrap items-center justify-start">
        <div title={`${trustData.rating} out of five star rating on Trustpilot`} className="flex flex-nowrap">
          <FiveStarsIcon className="w-[92px] md:w-[108px]" />
          <span className="sr-only">Five Star Review</span>

          <span
            className={cn(
              darkBg ? 'text-selphWhite-500' : 'text-selphGrey-900',
              'mx-1.5 flex items-baseline justify-between gap-1.5 pt-0.5 text-sm font-medium tracking-normal',
            )}
          >
            {trustData.reviews.toLocaleString()}
          </span>
        </div>

        <div className="flex flex-nowrap">
          <span
            className={cn(
              darkBg ? 'text-selphWhite-500' : 'text-selphGrey-900',
              'w-16 pt-0.5 text-sm font-medium tracking-normal',
            )}
          >
            reviews on
          </span>

          <span title="Trustpilot" className="ml-1">
            {darkBg ? <TPLogoWhite width="80" /> : <TPLogo width="80" />}
            <span className="sr-only">Trustpilot Logo</span>
          </span>
        </div>
      </a>
    </div>
  )
}

export default TPWithoutLabel
