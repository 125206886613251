import { useUi } from './use-ui'

export type ContainerStyles = 'contained' | 'none' | 'slim' | 'verticalContained'

export type PageProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
}

export const Page = ({ children, containerStyle = 'contained' }: PageProps) => {
  const containerStyles = {
    default: `relative grow`,
    contained: 'py-8 px-4 md:px-6 md:pt-10 md:pb-20 mx-auto w-full max-w-7xl',
    verticalContained: 'py-8 md:pt-10 md:pb-20',
    slim: 'p-4 sm:p-8 mx-auto',
    none: '',
  }
  const { className } = useUi({
    styles: { page: { options: containerStyles, selected: containerStyle } },
    name: 'Page',
  })

  return (
    <div className={className} id="bodyContent">
      {children}
    </div>
  )
}

Page.displayName = 'Page'

export default Page
