import { RefObject, useEffect, useRef, useState } from 'react'
import { useInstantSearch, Configure, Index, Hits, useSearchBox } from 'react-instantsearch'
import { cn } from '../../helpers/tailwind'
import { AlgoliaBlogHitRecord, AlgoliaProductHitRecord, Hit } from './hit'
import { productsIndex, blogsIndex } from './algolia-search'
import * as UI from '@/ui'

export type MobileSearch = { searchOpen: boolean; setSearchOpen: (v: boolean) => void }
type SearchResultsProps = {
  mobileSearch?: MobileSearch
}

export const SearchResults = ({ mobileSearch }: SearchResultsProps) => {
  const { indexUiState, status, renderState } = useInstantSearch()
  const { refine } = useSearchBox()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  // Effect to handle visibility with a slight delay to avoid flickering
  useEffect(() => {
    const hasValidQuery = indexUiState.query && indexUiState.query.length >= 2

    if (!hasValidQuery) {
      setIsVisible(false)
      return
    }

    // Only show when we have valid query and not in a loading state
    if (status !== 'loading' && status !== 'stalled') {
      // Small delay to prevent flickering during rapid status changes
      const timer = setTimeout(() => {
        setIsVisible(true)
      }, 50)
      return () => clearTimeout(timer)
    }
  }, [indexUiState.query, status])

  // Clear the search query to close the results container
  const clearSearchQuery = () => {
    refine('')
  }

  useClickOutside(containerRef, () => clearSearchQuery())

  const handleClick = () => {
    clearSearchQuery()
    isMobileSearchOpen && mobileSearch.setSearchOpen(false)
  }

  const hasProductResults = hasSearchResults(renderState, productsIndex)
  const hasBlogResults = hasSearchResults(renderState, blogsIndex)

  const isMobileSearchOpen = mobileSearch?.searchOpen

  // Don't render anything if we don't have a query at all
  if (!indexUiState.query) {
    return null
  }

  return (
    <NoResultsBoundary
      fallback={
        indexUiState.query.length > 1 && (
          <NoResults isMobileSearchOpen={mobileSearch?.searchOpen || false} isVisible={isVisible} />
        )
      }
    >
      <div
        ref={containerRef}
        className={cn(
          isVisible ? 'opacity-100' : 'opacity-0',
          // Hide completely when no valid query
          indexUiState.query.length < 2 ? 'hidden' : 'block',
          'z-20 mt-3 -ml-10 flex w-fit flex-col items-center bg-selphWhite-100 max-md:ml-4 md:absolute md:-mt-12 md:ml-0 md:w-xl md:shadow-2xl',
          'transition-all duration-200',
        )}
      >
        <Index indexName={productsIndex}>
          <Configure hitsPerPage={3} />

          {hasProductResults && (
            <div
              className={cn(
                isMobileSearchOpen ? 'bg-selphGreen-600' : 'bg-selphWhite-100',
                'flex w-full justify-between px-6 py-2',
              )}
            >
              <UI.Paragraph weight="regular" color={isMobileSearchOpen ? 'white' : 'black'}>
                Products:
              </UI.Paragraph>

              <UI.Link
                to={['searchResults', { query: indexUiState.query }]}
                color={isMobileSearchOpen ? 'primaryDarkBg' : 'primary'}
                onClick={handleClick}
              >
                View all
              </UI.Link>
            </div>
          )}

          <Hits
            hitComponent={(props) => (
              <Hit {...props} hit={props.hit as AlgoliaProductHitRecord} mobileSearch={mobileSearch} />
            )}
            className="w-full"
          />
        </Index>

        <Index indexName={blogsIndex}>
          <Configure hitsPerPage={2} />

          {hasBlogResults && (
            <div
              className={cn(
                isMobileSearchOpen ? 'bg-selphGreen-600' : 'bg-selphWhite-100',
                'flex w-full justify-between px-6 py-2',
              )}
            >
              <UI.Paragraph weight="regular" color={isMobileSearchOpen ? 'white' : 'black'}>
                Articles:
              </UI.Paragraph>

              {!hasProductResults && (
                <UI.Link
                  to={['searchResults', { query: indexUiState.query }]}
                  color={isMobileSearchOpen ? 'primaryDarkBg' : 'primary'}
                  onClick={handleClick}
                >
                  View all
                </UI.Link>
              )}
            </div>
          )}

          <Hits
            hitComponent={(props) => (
              <Hit {...props} hit={props.hit as AlgoliaBlogHitRecord} mobileSearch={mobileSearch} />
            )}
            className="w-full"
          />
        </Index>
      </div>
    </NoResultsBoundary>
  )
}

export default SearchResults

// Type guard to check if renderState index has hits with results
function hasSearchResults(renderState: Record<string, unknown>, indexName: string): boolean {
  if (!(indexName in renderState)) return false

  const indexState = renderState[indexName] as any

  // For different possible structures in Algolia's renderState
  if (indexState?.hits && Array.isArray(indexState.hits) && indexState.hits.length > 0) {
    return true
  }

  if (indexState?.hits?.items && Array.isArray(indexState.hits.items) && indexState.hits.items.length > 0) {
    return true
  }

  return false
}

function useClickOutside(ref: RefObject<HTMLElement>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      handler()
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

function NoResultsBoundary({ children, fallback }: { children: React.ReactNode; fallback: React.ReactNode }) {
  const { results, status, renderState } = useInstantSearch()

  const hasProductResults = hasSearchResults(renderState, productsIndex)
  const hasBlogResults = hasSearchResults(renderState, blogsIndex)

  if (!results.__isArtificial && status !== 'loading' && !hasProductResults && !hasBlogResults) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

function NoResults({ isMobileSearchOpen, isVisible }: { isMobileSearchOpen: boolean; isVisible: boolean }) {
  const { indexUiState } = useInstantSearch()

  return (
    <div
      className={cn(
        isMobileSearchOpen ? 'bg-selphGreen-600 text-selphWhite-500' : 'bg-selphWhite-100 text-selphBlack',
        isVisible ? 'opacity-100' : 'opacity-0',
        'flex h-24 w-full items-center justify-center px-4 py-6 transition-all duration-400 md:-mt-12 md:shadow-2xl',
      )}
    >
      <UI.Paragraph color={isMobileSearchOpen ? 'white' : 'black'} className="w-full text-center">
        Sorry, there are no results for <i>&ldquo;{indexUiState.query}&rdquo;.</i>
      </UI.Paragraph>
    </div>
  )
}
