import Header from '../header'
import Footer from '../footer'
import { ContainerStyles } from '../ui/page'
import { useUi } from '../ui/use-ui'
import { useNotification } from '../ui/use-notification'
import * as UI from '@/ui'

export type BackgroundColour = 'offWhite' | 'none' | 'lightishGrey'
export type LayoutProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
  backgroundColour?: BackgroundColour
  darkBreadcrumbBg?: boolean
}

export const Layout = ({ children, containerStyle, backgroundColour = 'offWhite', darkBreadcrumbBg }: LayoutProps) => {
  const { content } = useNotification()

  const backgroundColours = {
    offWhite: 'bg-selphWhite-500',
    lightishGrey: 'bg-selphGrey-100',
    none: '',
  }

  const { className } = useUi({
    styles: { layout: { options: backgroundColours, selected: backgroundColour } },
    name: 'Layout',
  })

  return (
    <div className={`relative flex min-h-screen flex-col overflow-clip ${className}`}>
      <Header darkBreadcrumbBg={darkBreadcrumbBg} />

      <UI.Page containerStyle={containerStyle}>
        {content && <UI.Notification {...content} className="mb-3" />}

        {children}
      </UI.Page>

      <Footer />
    </div>
  )
}

export default Layout
