import { useRouter } from 'next/router'
import { NextPageWithLayout } from '../../pages/_app.page'
import AdminLayout from '../--admin--/admin-layout'
import { Layout, LearnLayout } from '.'

export const LayoutManager = ({ Component, pageProps }: { Component: NextPageWithLayout; pageProps: any }) => {
  const { pathname } = useRouter()

  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />)
  }

  let ActiveLayout = Layout

  const layoutProps =
    typeof Component.layoutProps === 'function' ? Component.layoutProps(pageProps) : Component.layoutProps

  const isAdmin = pathname.startsWith('/admin')
  if (isAdmin) {
    ActiveLayout = AdminLayout
  }

  const isLearnPage = pathname.startsWith('/learn')
  if (isLearnPage) {
    ActiveLayout = LearnLayout
  }

  return (
    <ActiveLayout {...layoutProps}>
      <Component {...pageProps} />
    </ActiveLayout>
  )
}

export default LayoutManager
