export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BasketLineModifier: ['BloodCollectionOption', 'TestAddon'],
    Job: ['EmailJob', 'FidelityJob', 'TdlRegUploadJob', 'TuliApptConfEmailJob'],
    LabTestOrderedProductUserSubAction: [
      'ActivateKitLabTestOrderedProductUserAction',
      'ScheduleBloodCollectionOrderedProductUserAction',
    ],
    OrderedProduct: ['CgmOrderedProduct', 'GiftCardOrderedProduct', 'LabTestOrderedProduct'],
    Product: [
      'BespokeLabTestProduct',
      'CgmProduct',
      'CoachingProduct',
      'GiftCardProduct',
      'LabTestProduct',
      'SupplementProduct',
    ],
    ReportMeta: ['CgmReportMeta', 'LabTestReportMeta'],
    Shippable: ['KitActivationInfo', 'Shipment'],
    ShippableItem: ['KitDefinition', 'SupplementProduct'],
    TestInfoOrTestProfile: ['TestInfo', 'TestProfile'],
    UserAction: ['LabTestOrderedProductUserAction'],
  },
}
export default result
