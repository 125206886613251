import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'
import * as UI from '@/ui'
import { cn } from '../../helpers/tailwind'

export const TPCompact = ({ trustData, darkBg }: { trustData: TrustPilotData; darkBg?: boolean }) => {
  return (
    <a target="_blank" href={trustData.url} className="font-helvetica w-fit">
      <UI.Block gap="xs" className="flex items-start" display="flexCol">
        <span title={`${trustData.rating} out of five star rating on Trustpilot`}>
          <FiveStarsIcon className="mr-1 w-28" />
          <span className="sr-only">Five Star Review</span>
        </span>

        <span
          className={cn(darkBg ? 'text-white' : 'text-selphGrey-900', 'w-28 text-sm font-medium')}
        >{`${trustData.reviews.toLocaleString()} reviews on`}</span>

        <span title="Trustpilot">
          {darkBg ? <TPLogoWhite width="80" /> : <TPLogo width="80" />}
          <span className="sr-only">Trustpilot Logo</span>
        </span>
      </UI.Block>
    </a>
  )
}

export default TPCompact
