import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'

export const TPMicroReview = ({ trustData }: { trustData: TrustPilotData }) => {
  return (
    <a target="_blank" href={trustData.url} className="font-helvetica mx-auto flex h-6 w-fit items-baseline text-sm">
      <div className="mr-1 font-normal">See our</div>

      <div className="mr-1 font-semibold"> {`${trustData.reviews.toLocaleString()}`}</div>
      <div className="mr-1.5 font-medium"> reviews on</div>

      <span title="Trustpilot">
        <TPLogo width="80" className={`relative top-[2px] mx-auto`} />
        <span className="sr-only">Trustpilot Logo</span>
      </span>
    </a>
  )
}

export default TPMicroReview
