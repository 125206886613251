export type LabelProps = LabelPropsDefault | LabelPropsOption

export type LabelPropsShared = {
  children: React.ReactNode
}

export type LabelPropsDefault = {
  type?: 'default'
  htmlFor: string
  text?: never
} & LabelPropsShared

export type LabelPropsOption = {
  type?: 'option'
  htmlFor?: never
  text: string
} & LabelPropsShared

export const Label = ({ type = 'default', text, htmlFor, children }: LabelProps) => (
  <>
    {type === 'default' && (
      <label htmlFor={htmlFor} className="block pb-1 text-base text-selphBlack">
        {children}
      </label>
    )}
    {type === 'option' && (
      <label className="align-center flex">
        <div>{children}</div>
        <div className="mt-1 ml-3 text-sm font-medium text-selphBlack">{text}</div>
      </label>
    )}
  </>
)

Label.displayName = 'Form.Label'

export default Label
