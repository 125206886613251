import { useField, useFormikContext } from 'formik'
import { map } from 'lodash-es'
import { Field } from 'formik'
import { useEffect } from 'react'
import { FormikValues } from 'formik/dist/types'
import { cn } from '../../../../helpers/tailwind'
import { dependsMatch, ValidateDepends, ValidateDependsValue, dependsParseValue } from '../validation'
import { useUi } from '../../use-ui'
import { type RadioPropsCommon } from './index'
import Tick_Icon from '@/images/icons/tick_icon.svg?svgr'
import * as UI from '@/ui'

export type FormikRadioGroupProps = {
  optionStyle?: 'button' | 'radio' | 'range'
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
  buttonSize?: 'full' | 'fit' | 'sm' | 'md' | 'lg'
} & RadioPropsCommon

export const FormikRadioGroup = ({
  name,
  options,
  errorMessage,
  gridGap,
  gridSize,
  gridAlign,
  gridColVerticalAlign,
  depends,
  setIsHidden,
  optionStyle = 'radio',
  buttonSize = 'full',
  ...props
}: FormikRadioGroupProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })

  options = Array.isArray(options) ? Object.assign({}, ...options.map((v) => ({ [v]: v }))) : options

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  const buttonSizes = {
    default: '',
    full: 'flex-1',
    fit: 'w-fit',
    sm: 'w-20',
    md: 'w-32',
    lg: 'w-48',
  }

  const { className } = useUi({
    name: 'Form.RadioGroup',
    className: cn(
      errorMessage
        ? 'ring-selphRed-400 text-selphRed-600 border-selphRed-500'
        : meta.touched
          ? 'ring-green-500 text-green-600 border-green-300'
          : 'text-indigo-600 border-gray-300',
      props.readOnly && 'cursor-not-allowed bg-selphGrey-100',
      'focus:ring-indigo-500 h-4 w-4',
    ),
  })

  const buttonStyle = useUi({
    styles: {
      size: { options: buttonSizes, selected: buttonSize },
    },
    name: 'Form.RadioGroup',
  })

  const labelProps = (selected: boolean) => ({
    className: cn(
      selected
        ? props?.readOnly
          ? 'bg-selphGreen-500 cursor-not-allowed'
          : 'bg-selphGreen-500 cursor-pointer'
        : errorMessage
          ? 'bg-selphWhite-300'
          : props?.readOnly
            ? 'bg-selphWhite-200 cursor-not-allowed'
            : 'bg-selphWhite-300 cursor-pointer',
      'flex w-full items-center gap-x-1 m-0 mr-3 mb-3 rounded-full py-2 px-3 md:py-3 md:px-5',

    ),
  })

  const spanProps = (selected: boolean, readOnly: boolean) => ({
    className: cn(
      selected ? 'text-selphWhite-500' : readOnly ? 'text-selphWhite-700' : 'text-selphBlack',
      'pl-2 font-normal',
    ),
  })

  return (
    <UI.Block gap="xs">
      {
        {
          radio: (
            <UI.Grid gap={gridGap} size={gridSize} align={gridAlign} colVerticalAlign={gridColVerticalAlign}>
              {map(options, (value, label) => {
                return (
                  <div key={value + label} className={gridSize === 'flex' ? 'mr-5' : ''}>
                    <UI.Form.Label type="option" text={label}>
                      <Field type="radio" name={name} value={`${value}`} {...props} className={className} />
                    </UI.Form.Label>
                  </div>
                )
              })}
            </UI.Grid>
          ),
          button: (
            <div className="flex flex-wrap gap-x-3">
              {map(options, (value, label) => {
                const selected = (values as FormikValues)[name] === dependsParseValue(value)
                return (
                  <div className={buttonStyle.className} key={value + label}>
                    <label {...labelProps(selected)}>
                      {!props.readOnly && (
                        <Field type="radio" name={name} value={`${value}`} className="hidden" {...props} />
                      )}
                      <div>
                        {selected ? (
                          <span className="relative flex size-5 shrink-0 items-center justify-center rounded-full border border-selphOrange-500 bg-selphOrange-500 p-0.5 text-selphWhite-300">
                            <Tick_Icon className="absolute h-3" />
                          </span>
                        ) : (
                          <span
                            className={cn(
                              props.readOnly
                                ? 'border-selphWhite-600 bg-selphWhite-200'
                                : 'border-selphBlack bg-selphWhite-300',
                              'flex size-5 shrink-0 items-center justify-center rounded-full border',
                            )}
                          />
                        )}
                      </div>
                      <span {...spanProps(selected, !!props.readOnly)}>{label}</span>
                    </label>
                  </div>
                )
              })}
            </div>
          ),
          range: (
            <UI.Grid
              size="flow"
              gap="none"
              className={`${
                errorMessage ? 'border-red-400' : 'border-gray-400'
              } w-fit overflow-hidden rounded-lg border py-[8px]`}
            >
              {map(options, (value, label) => {
                const selected = (values as FormikValues)[name] === dependsParseValue(value)
                return (
                  <div className="inline-block border-r last:border-0" key={value + label}>
                    <label
                      className={`${
                        selected ? 'bg-selphBlue-200 text-white' : `bg-white text-gray-500`
                      } cursor-pointer px-5 py-3`}
                    >
                      <Field type="radio" name={name} value={`${value}`} className="hidden" />
                      <span className="text-sm">{label}</span>
                    </label>
                  </div>
                )
              })}
            </UI.Grid>
          ),
        }[optionStyle]
      }

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </UI.Block>
  )
}

FormikRadioGroup.displayName = 'Form.RadioGroup.FormikRadioGroup'

export default FormikRadioGroup
