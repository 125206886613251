import { useUi, type Breakpoints } from './use-ui'

export type DividerSizes = 'medium' | 'mediumFull' | 'large'
export type DividerMargin = 'none' | 'medium' | 'large'
export type DividerColours = 'light' | 'dark'

export const Divider = ({
  className,
  margin = 'medium',
  size = 'medium',
  color = 'light',
}: {
  margin?: DividerMargin
  color?: DividerColours
  size?: Breakpoints<DividerSizes>
  className?: string
}) => {
  const sizes = {
    default: '',
    medium: 'h-px',
    large: 'h-[2px]',
  }

  const margins = {
    none: '',
    medium: 'my-2',
    large: 'my-3',
  }

  const colors = {
    default: '',
    light: 'bg-selphWhite-600',
    dark: 'bg-selphBlack',
  }

  const ui = useUi({
    styles: {
      size: { options: sizes, selected: size },
      margin: { options: margins, selected: margin },
      color: { options: colors, selected: color },
    },
    name: 'Divider',
    className: `${className} w-full rounded-sm`,
  })

  return <div className={ui.className} />
}

Divider.displayName = 'Divider'

export default Divider
